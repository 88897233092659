@font-face {
  font-family: Poppins-Regular;
  src: url(../fonts/Poppins/Poppins-Regular.ttf);
}
@font-face {
  font-family: Roboto;
  src: url(../fonts/Roboto/Roboto-Regular.ttf);
}
body,
html {
  font-size: 13px;
  font-family: "Roboto";
  background-color: #ececed;
  margin: 0;
  padding: 0;
  height: 100%;
  scroll-behavior: smooth;
  font-smooth: always;
}

#root {
  height: 100%;
}
/* Content */
.content {
  border: 1px solid #e5e5e5;
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.1),
    0px 3px 4px 0px rgba(0, 0, 0, 0.05), 0px 1px 8px 0px rgba(0, 0, 0, 0.02);
}

/* Alert Bar */

.MuiAlert-filledInfo {
  color: #fff;
  font-weight: 500;
  background-color: #f2994a;
}
.MuiAlert-root {
  display: flex;
  padding: 6px 16px;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.43;
  border-radius: 4px;
}
.MuiPaper-elevation0 {
  box-shadow: none;
}

.MuiAlert-icon {
  display: flex;
  opacity: 0.9;
  padding: 7px 0;
  font-size: 22px;
  margin-right: 12px;
}

.MuiAlert-message {
  padding: 8px 0;
}

.MuiAlert-message h6 {
  font-size: 1.175rem;
  font-weight: 600;
}

.login-background {
  background-image: url("../ui/static/yubikey-bg.webp");
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
